import { useState } from "react";

import Image from "next/image";
import DeclarationModalData from "../variables/variables.json";

import TranslatedMessage from "./TranslatedMessage";

import DialogScript from "./js/DialogScript";

import Tooltip from "./Tooltip";

const DeclarationModal = (props) => {
	// PROPS VARIABLE
	let propsMiasto = props.miasto;
	let propsPodatek = props.podatek;

	let startUrl;
	let startUrlProd = "https://przyjazne-deklaracje.pl/";
	let startUrlTest = "https://test.przyjazne-deklaracje.pl/";

	if (DeclarationModalData.miasta[propsMiasto].test) {
		startUrl = startUrlTest;
	} else {
		startUrl = startUrlProd;
	}

	let declarationLists = DeclarationModalData.declarationModal;
	let profileCode = "O4B";
	let templateScope = DeclarationModalData.miasta[propsMiasto].templateScope;
	let templateScopeFull = "&templateScope=" + templateScope;

	if (DeclarationModalData.miasta[propsMiasto].index) {
		templateScopeFull = "";
	}

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

	const Transportowe = () => {
		return (
			<main className="modal__content" id="podatek-od-srodkow-transportowych-content">
				<ul className="pd-custom-list">
					<li>Numer PESEL lub NIP (jeśli prowadzisz działalność gospodarczą)</li>
				</ul>
				<p>W zależności od celu złożenia i sytuacji pojazdu przydatne będą:</p>
				<ul className="pd-custom-list list-two-columns">
					<li>Dowód rejestracyjny pojazdu</li>
					<li>Umowa kupna / sprzedaży</li>
					<li>Decyzja o czasowym wycofaniu z ruchu lub jego skróceniu</li>
					<li>Decyzja o wyrejestrowaniu pojazdu</li>
					<li>Karta pojazdu</li>
					<li>Świadectwo zgodności</li>
					<li>Zaświadczenie o przeprowadzonym badaniu technicznym</li>
					<li>
						Wyciąg ze świadectwa homologacji pojazdu lub odpis decyzji zwalniającej pojazd z
						homologacji
					</li>
				</ul>
				{/* <CheckboxMaterial name="no-modal" onCheckbox={props.onCheckbox}>
          Nie pokazuj tego więcej
        </CheckboxMaterial> */}
				<div className="modal__action">
					<a
						className="pd-btn pd-btn--tertiary"
						href={
							startUrl +
							"kalkulatory/menadzer/menadzer-kalkulatorow?profileCode=" +
							profileCode +
							"&templateCode=" +
							"DT-1" +
							templateScopeFull
						}
					>
						Przygotuj DT-1
					</a>
				</div>
			</main>
		);
	};

	const Nieruchomosci = () => {
		return (
			<main className="modal__content" id="podatek-od-nieruchomosci-content">
				<div className="declaration-modal__container v2">
					<div className="declaration-modal__wrapper">
						<a
							className="declaration-modal__link"
							href={
								startUrl +
								"kalkulatory/menadzer/menadzer-kalkulatorow?profileCode=" +
								profileCode +
								"&templateCode=" +
								"DN-1" +
								templateScopeFull
							}
							tabIndex="0"
						></a>
						<div className="declaration-modal__box__header">
							<div className="declaration-modal__box__type">DN-1</div>
							<div className="declaration-modal__box__title">
								Dla osób prawnych
								<Tooltip
									content={
										<div>
											<p>
												Osoba prawna to podmiot posiadający nazwę i siedzibę np. spółka kapitałowa,
												fundacja, spółdzielnia. Wypełnij DN-1 jeśli składasz deklarację w imieniu
												podmiotu posiadającego osobowość prawną lub jednostki organizacyjnej
												nieposiadającej osobowości prawnej (np. spółki osobowej).
											</p>
										</div>
									}
								/>
							</div>
						</div>
						<div className="declaration-modal__box__description">
							Powstanie, korekta lub wygaśnięcie obowiązku podatkowego. Przejdź, aby utworzyć DN-1
							wraz z załącznikami ZDN-1, ZDN-2.
						</div>
					</div>
					<div className="declaration-modal__wrapper">
						<a
							className="declaration-modal__link"
							href={
								startUrl +
								"kalkulatory/menadzer/menadzer-kalkulatorow?profileCode=" +
								profileCode +
								"&templateCode=" +
								"IN-1" +
								templateScopeFull
							}
							tabIndex="0"
						></a>
						<div className="declaration-modal__box__header">
							<div className="declaration-modal__box__type">IN-1</div>
							<div className="declaration-modal__box__title">
								Dla osób fizycznych
								<Tooltip
									content={
										<div>
											<p>
												Osoba fizyczna to prawne określenie każdego człowieka, również osoby
												prowadzącej jednoosobową działalność gospodarczą. Osoba fizyczna to np. Jan
												Kowalski.
											</p>
											<p>
												Z reguły osoby fizyczne składają IN-1. Jednak, jeśli jesteś osobą fizyczną,
												ale posiadasz nieruchomość we współwłasności (lub współposiadaniu) z osobami
												prawnymi (np. spółką z o.o. lub organizacją nieposiadającą osobowości
												prawnej) wtedy wypełnij deklarację DN-1.
											</p>
										</div>
									}
								/>
							</div>
						</div>
						<div className="declaration-modal__box__description">
							Powstanie, korekta lub wygaśnięcie obowiązku podatkowego. Przejdź, aby utworzyć IN-1
							wraz z załącznikami ZIN-1, ZIN-2, ZIN-3.
						</div>
					</div>
				</div>
				<div className="modal__dropdown">
					<button
						className="modal__dropdown__action"
						aria-expanded={isDropdownOpen ? "true" : "false"}
						onClick={toggleDropdown}
					>
						Sprawdź co przygotować zanim zaczniesz
					</button>
					<div
						className={`modal__dropdown__content ${isDropdownOpen ? "active" : ""}`}
						role="region"
					>
						<ul className="pd-custom-list list-two-columns">
							<li>Numer PESEL lub NIP, jeżeli prowadzisz działalność gospodarczą</li>
							<li>Akt notarialny, umowę dzierżawy i ich skan</li>
							<li>Dane z księgi wieczystej oraz dane z ewidencji gruntów i budynków</li>
							<li>Dane osobowe i adresowe współwłaścicieli (dla IN-1)</li>
							<li>
								Jeżeli jesteś przedsiębiorcą: ewidencję środków trwałych, dokumentację
								architektoniczno-budowlaną, umowy najmu
							</li>
						</ul>
					</div>
				</div>
			</main>
		);
	};

	const Rolny = () => {
		return (
			<main className="modal__content" id="podatek-rolny-content">
				<div className="declaration-modal__container v2">
					<div className="declaration-modal__wrapper">
						<a
							className="declaration-modal__link"
							href={
								startUrl +
								"kalkulatory/menadzer/menadzer-kalkulatorow?profileCode=" +
								profileCode +
								"&templateCode=" +
								"DR-1" +
								templateScopeFull
							}
							tabIndex="0"
						></a>
						<div className="declaration-modal__box__header">
							<div className="declaration-modal__box__type">DR-1</div>
							<div className="declaration-modal__box__title">
								Dla osób prawnych
								<Tooltip
									content={
										<div>
											<p>
												Osoba prawna to podmiot posiadający nazwę i siedzibę np. spółka kapitałowa,
												fundacja, spółdzielnia. Wypełnij DR-1 jeśli składasz deklarację w imieniu
												podmiotu posiadającego osobowość prawną lub jednostki organizacyjnej
												nieposiadającej osobowości prawnej (np. spółki osobowej).
											</p>
										</div>
									}
								/>
							</div>
						</div>
						<div className="declaration-modal__box__description">
							Powstanie, korekta lub wygaśnięcie obowiązku podatkowego. Przejdź, aby utworzyć DR-1
							wraz z załącznikami ZDR-1, ZDR-2.
						</div>
					</div>
					<div className="declaration-modal__wrapper">
						<a
							className="declaration-modal__link"
							href={
								startUrl +
								"kalkulatory/menadzer/menadzer-kalkulatorow?profileCode=" +
								profileCode +
								"&templateCode=" +
								"IR-1" +
								templateScopeFull
							}
							tabIndex="0"
						></a>
						<div className="declaration-modal__box__header">
							<div className="declaration-modal__box__type">IR-1</div>
							<div className="declaration-modal__box__title">
								Dla osób fizycznych
								<Tooltip
									content={
										<div>
											<p>
												Osoba fizyczna to prawne określenie każdego człowieka, również osoby
												prowadzącej jednoosobową działalność gospodarczą. Osoba fizyczna to np. Jan
												Kowalski.
											</p>
											<p>
												Z reguły osoby fizyczne składają IR-1. Jednak, jeśli jesteś osobą fizyczną,
												ale posiadasz nieruchomość we współwłasności (lub współposiadaniu) z osobami
												prawnymi (np. spółką z o.o. lub organizacją nieposiadającą osobowości
												prawnej) wtedy wypełnij deklarację DR-1.
											</p>
										</div>
									}
								/>
							</div>
						</div>
						<div className="declaration-modal__box__description">
							Powstanie, korekta lub wygaśnięcie obowiązku podatkowego. Przejdź, aby utworzyć IR-1
							wraz z załącznikami ZIR-1, ZIR-2, ZIR-3.
						</div>
					</div>
				</div>
				<div className="modal__dropdown">
					<button
						className="modal__dropdown__action"
						aria-expanded={isDropdownOpen ? "true" : "false"}
						onClick={toggleDropdown}
					>
						Sprawdź co przygotować zanim zaczniesz
					</button>
					<div
						className={`modal__dropdown__content ${isDropdownOpen ? "active" : ""}`}
						role="region"
					>
						<ul className="pd-custom-list list-two-columns">
							<li>Numer PESEL lub NIP, jeżeli prowadzisz działalność gospodarczą</li>
							<li>Akt notarialny lub jego skan</li>
							<li>Dane z księgi wieczystej</li>
							<li>Informacje dot. klasyfikacji gruntów np. wypis z ewidencji gruntów i budynków</li>
							<li>Umowy najmu lub dzierżawy oraz ich skany</li>
							<li>Dane osobowe i adresowe współwłaścicieli jeśli składasz IR-1</li>
							<li>Ewidencję środków trwałych jeśli jesteś przedsiębiorcą</li>
						</ul>
					</div>
				</div>
			</main>
		);
	};

	const Lesny = () => {
		return (
			<main className="modal__content" id="podatek-lesny-content">
				<div className="declaration-modal__container v2">
					<div className="declaration-modal__wrapper">
						<a
							className="declaration-modal__link"
							href={
								startUrl +
								"kalkulatory/menadzer/menadzer-kalkulatorow?profileCode=" +
								profileCode +
								"&templateCode=" +
								"DL-1" +
								templateScopeFull
							}
							tabIndex="0"
						></a>
						<div className="declaration-modal__box__header">
							<div className="declaration-modal__box__type">DL-1</div>
							<div className="declaration-modal__box__title">
								Dla osób prawnych
								<Tooltip
									content={
										<div>
											<p>
												Osoba prawna to podmiot posiadający nazwę i siedzibę np. spółka kapitałowa,
												fundacja, spółdzielnia. Wypełnij DL-1 jeśli składasz deklarację w imieniu
												podmiotu posiadającego osobowość prawną lub jednostki organizacyjnej
												nieposiadającej osobowości prawnej (np. spółki osobowej).
											</p>
										</div>
									}
								/>
							</div>
						</div>
						<div className="declaration-modal__box__description">
							Powstanie, korekta lub wygaśnięcie obowiązku podatkowego. Przejdź, aby utworzyć DL-1
							wraz z załącznikami ZDL-1, ZDL-2.
						</div>
					</div>
					<div className="declaration-modal__wrapper">
						<a
							className="declaration-modal__link"
							href={
								startUrl +
								"kalkulatory/menadzer/menadzer-kalkulatorow?profileCode=" +
								profileCode +
								"&templateCode=" +
								"IL-1" +
								templateScopeFull
							}
							tabIndex="0"
						></a>
						<div className="declaration-modal__box__header">
							<div className="declaration-modal__box__type">IL-1</div>
							<div className="declaration-modal__box__title">
								Dla osób fizycznych
								<Tooltip
									content={
										<div>
											<p>
												Osoba fizyczna to prawne określenie każdego człowieka, również osoby
												prowadzącej jednoosobową działalność gospodarczą. Osoba fizyczna to np. Jan
												Kowalski.
											</p>
											<p>
												Z reguły osoby fizyczne składają IL-1. Jednak, jeśli jesteś osobą fizyczną,
												ale posiadasz nieruchomość we współwłasności (lub współposiadaniu) z osobami
												prawnymi (np. spółką z o.o. lub organizacją nieposiadającą osobowości
												prawnej) wtedy wypełnij deklarację DL-1.
											</p>
										</div>
									}
								/>
							</div>
						</div>
						<div className="declaration-modal__box__description">
							Powstanie, korekta lub wygaśnięcie obowiązku podatkowego. Przejdź, aby utworzyć IL-1
							wraz z załącznikami ZIL-1, ZIL-2, ZIL-3.
						</div>
					</div>
				</div>
				<div className="modal__dropdown">
					<button
						className="modal__dropdown__action"
						aria-expanded={isDropdownOpen ? "true" : "false"}
						onClick={toggleDropdown}
					>
						Sprawdź co przygotować zanim zaczniesz
					</button>
					<div
						className={`modal__dropdown__content ${isDropdownOpen ? "active" : ""}`}
						role="region"
					>
						<ul className="pd-custom-list list-two-columns">
							<li>Numer PESEL lub NIP (jeżeli prowadzisz działalność gospodarczą)</li>
							<li>Akt notarialny lub jego skan</li>
							<li>Dane z księgi wieczystej</li>
							<li>Informacje dot. klasyfikacji gruntów np. wypis z ewidencji gruntów i budynków</li>
							<li>Umowy najmu lub dzierżawy oraz ich skany</li>
							<li>Dane osobowe i adresowe współwłaścicieli jeśli składasz IL-1</li>
							<li>Ewidencję środków trwałych jeśli jesteś przedsiębiorcą</li>
						</ul>
					</div>
				</div>
			</main>
		);
	};

	const taxesMap = {
		"podatek-od-srodkow-transportowych": Transportowe,
		"podatek-od-nieruchomosci": Nieruchomosci,
		"podatek-rolny": Rolny,
		"podatek-lesny": Lesny,
	};

	return (
		<>
			{declarationLists.lists.map((lists, index) => {
				const Tax = taxesMap[lists.data];
				return (
					<div
						className="modal micromodal-slide"
						id={lists.data}
						aria-hidden="true"
						// aria-labelledby="my-dialog-title"
						// aria-describedby="my-dialog-description"
						key={index}
					>
						<div className="modal__overlay" tabIndex="-1" data-a11y-dialog-hide></div>
						<div
							className="modal__container"
							aria-modal="true"
							aria-labelledby={lists.data + "-title"}
						>
							<div className="modal__column__left">
								{lists.column.map((column, index) => (
									<Image unoptimized alt="" src={column.image} width={80} height={80} key={index} />
								))}
							</div>
							<div className="modal__column__right">
								<header className="modal__header">
									<h2 className="modal__title" id={lists.data + "-title"}>
										<TranslatedMessage id={lists.title} />
									</h2>
									<button className="modal__close" aria-label="Zamknij okno" data-a11y-dialog-hide>
										<span className="close"></span>
									</button>
									<div className="declaration-modal__box__caption">
										<TranslatedMessage id={lists.description} />
									</div>
								</header>
								{Tax && <Tax />}
							</div>
						</div>
					</div>
				);
			})}

			{/* <button data-a11y-dialog-show="podatek-od-nieruchomosci">Open</button> */}

			<DialogScript />
		</>
	);
};

export default DeclarationModal;

import { useIntl } from "react-intl";

import dynamic from "next/dynamic";

import SEO from "../../components/SEO";
import Header from "../../components/Header";
import Slider from "../../components/Slider";
import Footer from "../../components/Footer";

import Section from "../../components/Section";
import SectionBox from "../../components/SectionBox";
import DeclarationBox from "../../components/DeclarationBox";
import DeclarationBoxItem from "../../components/DeclarationBoxItem";
import DeclarationBoxItemMinutes from "../../components/DeclarationBoxItemMinutes";
import DeclarationModal from "../../components/DeclarationModal";
import BackgroundSection from "../../components/BackgroundSection";

import Variables from "../../variables/variables.json";

export default function HomeLayout(props) {
	const intl = useIntl();

	let Miasto = props.miasto;
	let Podatek = props.podatek;

	const CityIndexSection1PL = dynamic(() =>
		import("../../content/" + Variables.miasta[Miasto].name.url + "/index/section-1_pl.mdx"),
	);

	const CityIndexSection2PL = dynamic(() =>
		import("../../content/" + Variables.miasta[Miasto].name.url + "/index/section-2_pl.mdx"),
	);

	const CityIndexSection3PL = dynamic(() =>
		import("../../content/" + Variables.miasta[Miasto].name.url + "/index/section-3_pl.mdx"),
	);

	return (
		<>
			{/* <Helper miasto={Miasto} /> */}

			{/* HEAD */}
			<SEO
				miasto={Miasto}
				podatek={Podatek}
				canonical={"https://przyjazne-deklaracje.pl/" + Variables.miasta[Miasto].name.url + "/"}
				noindex={Variables.miasta[Miasto].test}
				nofollow={Variables.miasta[Miasto].test}
			/>

			{/* TOP */}
			<Header miasto={Miasto} />

			<Slider miasto={Miasto} podatek={Podatek} typeHero="slider" />

			{/* SECTION */}

			<Section mdx>
				<CityIndexSection1PL />
			</Section>

			<Section id="wypelnij-deklaracje" title="sections.wypelnijdeklaracje.title">
				<DeclarationBox miasto={Miasto} center minutes5>
					{Variables.miasta[Miasto].declarationBoxItem[Podatek].map((declarationBoxItem, index) => {
						return (
							<DeclarationBoxItem
								miasto={Miasto}
								podatek={declarationBoxItem.title}
								key={index}
								soon={declarationBoxItem.soon && true}
								noReadMore={declarationBoxItem.noReadMore && true}
							/>
						);
					})}
					{Variables.miasta[Miasto].declarationBoxItem[Podatek].length < 6 && (
						<DeclarationBoxItemMinutes />
					)}
				</DeclarationBox>
				<DeclarationModal miasto={Miasto} />
			</Section>

			<Section primary title="sections.cozyskujesz.title">
				<SectionBox section="cozyskujesz" />
			</Section>

			<Section lightdark mdx>
				<CityIndexSection2PL />
			</Section>

			<Section primary title="sections.jakkorzystac.title">
				<SectionBox section="jakkorzystac" round />
			</Section>

			<Section lightdark mdx>
				<CityIndexSection3PL />
			</Section>

			<Section primary title="sections.wynikukalkulacji.title">
				<SectionBox section="wynikukalkulacji" />
			</Section>

			<BackgroundSection
				miasto={Miasto}
				section="lokalnepodatki"
				textAlign="right"
				button="text"
				title
				subtitle
				fillLeftBottom
			/>

			{/* BOTTOM */}

			<Footer miasto={Miasto} />
		</>
	);
}
